import { AppDispatch, RootState } from './store';
import axios from 'axios';
import { fetchJobsRequest, fetchJobsSuccess, fetchJobsFailure, fetchJobDetailsRequest, fetchJobDetailsSuccess, fetchJobDetailsFailure,setHasMore } from '../reducers/jobReducers';
import { Job } from '@/types/types';


interface JobApiResponse {
  status: number;
  data: Job; // The data property contains a Job object
}

const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";

  export const fetchJobs = (page: number) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(fetchJobsRequest());

            const response = await axios.get<{ data: Job[] }>(
                `${baseUrl}jobs?page=${page}&limit=10`
            );

            const jobsData = response.data.data.map(job => ({
                id: job.id,
                date: job.date,
                companyLogo: job.companyLogo,
                companyName: job.companyName,
                jobTitle: job.jobTitle,
                jobDescription: job.jobDescription,
                location: job.location,
                employment: job.employment,
                applyLink: job.applyLink,
                postDate: job.postDate,
                shortDescription: job.shortDescription      
            }));

            const hasMore = jobsData.length === 10;
            if (!hasMore) {
              dispatch(setHasMore(false));
            }
            // Dispatch and return the action for use in components
            return dispatch(fetchJobsSuccess({ jobs: jobsData, hasMore }));
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
            dispatch(fetchJobsFailure(errorMessage));
        }
    };
};


// Delete Job Action
export const deleteJob = (jobId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      // Confirm deletion
      const isConfirmed = window.confirm("Are you sure you want to delete this job?");
      if (!isConfirmed) return; // If not confirmed, exit the function

      // Send DELETE request to the server
      await axios.delete(`${baseUrl}jobs/${jobId}`);

      // Remove the job from the Redux state without re-fetching
      const { jobs } = getState().job;
      dispatch({
        type: 'job/fetchJobsSuccess',
        payload: {
          jobs: jobs.filter((j: Job) => j.id !== jobId),
          hasMore: jobs.length > 1, // Set hasMore based on the current job list
        },
      });

      console.log(`Job ${jobId} deleted successfully.`);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(fetchJobsFailure(errorMessage));
    }
  };
};


// Edit Job Action
export const editJob = (jobId: string, updatedJobData: Partial<Job>) => {
  return async (dispatch: AppDispatch) => {
    try {
      // Optionally, you can dispatch a request action to set loading state
      dispatch(fetchJobsRequest());

      // Send PUT request to the server to update the job details
      await axios.put(`${baseUrl}jobs/${jobId}`, updatedJobData);
      // Fetch the updated list of jobs after editing
      dispatch(fetchJobs(1)); // Optionally reset to the first page or keep the current page logic
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(fetchJobsFailure(errorMessage));
    }
  };
};

export const fetchJobDetails = (jobId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(fetchJobDetailsRequest());  // Set loading state

      const response = await axios.get<JobApiResponse>(`${baseUrl}jobs/${jobId}`);

      if (response.status >= 200 && response.status < 300) {
        dispatch(fetchJobDetailsSuccess(response.data.data)); // Success response
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      let errorMessage = 'An unknown error occurred';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.status === 404) {
            // Custom 404 error message for job not found
            errorMessage = 'Job not found (404)';
          } else {
            errorMessage = `Server error: ${error.response.status}`;
          }
        } else if (error.request) {
          errorMessage = 'Network error: No response received from the server';
        } else {
          errorMessage = `Request setup error: ${error.message}`;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      console.error('Error fetching job details:', errorMessage);
      dispatch(fetchJobDetailsFailure(errorMessage)); // Dispatch error
    }
  };
};
