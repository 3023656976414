import React from 'react';
import NewsLetter from './NewsLetter';
import kodekamp from '../../assets/kampkode_transparent_footer.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup } from '@fortawesome/fontawesome-common-types';
import {
  faGithub,
  faXTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Badge } from '../ui/badge';

interface FooterItem {
  label: string;
  link: string;
  tag?: string;
}

interface FooterItems {
  services: FooterItem[];
  company: FooterItem[];
  'For Students': FooterItem[];
  Social: FooterItem[];
}

interface FooterSectionProps {
  title: string;
  items: FooterItem[];
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, items }) => (
  <div className='flex flex-col text-start gap-3.5 mx-10 md:mx-1 my-4 '>
    <h4 className="font-light text-[#D6BBFB] text-lg text-[14px]">{title}</h4>
    {items.map((item) => (
      <p className='font-normal text-[16px] text-[#E9D7FE] hover:-translate-y-0.5 transition-all duration-200'>
        <a
          href={item.link}
          key={item.label}
          target={item.link.includes('https') ? '_blank' : '_self'}
          rel={item.link.includes('https') ? 'noopener noreferrer' : ''}
        >
          {item.label}
          {item.tag && <Badge variant="outline" className='mx-1 text-white'>{item.tag}</Badge>}
        </a>
      </p>
    ))}
  </div>
);

const FooterPage: React.FC = () => {
  const footerItems: FooterItems = {
    services: [
      { label: 'CRT', link: '/crt', tag: 'Popular' },
      { label: 'Internships', link: '/internships' },
      { label: 'Projects', link: '/projects' },
      { label: 'Resume Maker', link: 'https://resume.kampkode.tech/', tag: 'New' },
      { label: 'Roadmap', link: '/roadmap', tag: 'Soon' },
    ],
    company: [
      { label: 'About us', link: '/about-us' },
      { label: 'Features', link: '/#features ' },
      { label: 'Pricing', link: '/crt#pricing' },
      { label: 'Contact Us', link: '/contact-us' },
      { label: 'Privacy Policy', link: '/privacy-policy' },
    ],
    'For Students': [
      { label: 'Quiz\'s', link: '/quizzes' },
      { label: 'Reading Material', link: '/reading-materials' },
      { label: 'Live Classes', link: '/live-classes' },
      { label: 'Flash Cards', link: '/flashcards' },
      { label: 'Skill Assessment', link: '/skill-assignments' },
    ],
    Social: [
      { label: 'LinkedIn', link: 'https://linkedin.com' },
      { label: 'Instagram', link: 'https://instagram.com' },
      { label: 'YouTube', link: 'https://youtube.com' },
      { label: 'X(Twitter)', link: 'https://twitter.com' },
      { label: 'GitHub', link: 'https://github.com' },
    ],
  };

  return (
    <div className="bg-[#53389E] text-white relative h-fit overflow-hidden">
        <img src="./FooterBG.png" alt="" className='absolute bottom-0 right-0 h-[400px] w-[400px]'/>
      <NewsLetter />
      <div className="flex flex-col md:h-96 md:flex-row justify-center items-center py-5 font-Inter md:py-8 md:space-y-5 gap-10 md:mx-4 realtive overflow-hidden">
        <img src="./FooterBG.png" alt="" className='absolute right-0 scale-125 md:scale-75 translate-y-96 md:translate-x-32 md:-translate-y-2 -z-20  '/>
        <div className="flex flex-col items-center text-center md:w-1/2">
          <img src={kodekamp} alt="logo" className="w-52 bg-slate-200 rounded-lg mb-2" />
          <p className="w-4/5 md:w-62 font-light text-start mt-8 lg:pl-6">Empowering students with the skills and knowledge to excel in their careers.</p>
        </div>
        <div className="md:w-full max-w-4xl grid grid-cols-2  md:grid-cols-4  sm:gap-20 md:gap-4 text-center mr-5 ">
          <FooterSection title="Product" items={footerItems.services} />
          <FooterSection title="Company" items={footerItems.company} />
          <FooterSection title="Resources" items={footerItems['For Students']} />
          <FooterSection title="Social" items={footerItems.Social} />
        </div>
      </div>
      <hr className="h-px my-2 mx-4 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className="py-10 text-center flex flex-col md:flex-row gap-5 justify-between mx-20">
        <p className='mb-5 order-2 md:order-1 font-extralight'>&#169; 2024 Kampkode Technologies LLP. All Rights Reserved.</p>
        <div className="order-1 md:order-2">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin as IconLookup} className="text-white text-2xl mx-2 hover:text-gray-300 cursor-pointer" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram as IconLookup} className="text-white text-2xl mx-2 hover:text-gray-300 cursor-pointer" />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube as IconLookup} className="text-white text-2xl mx-2 hover:text-gray-300 cursor-pointer" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter as IconLookup} className="text-white text-2xl mx-2 hover:text-gray-300 cursor-pointer" />
          </a>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub as IconLookup} className="text-white text-2xl mx-2 hover:text-gray-300 cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
