
import React from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { cn } from "../magicui/lib/utils";
import { Checkbox } from "../ui/checkbox";

export default function SignupFormDemo() {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted");
  };
  return (
    <div className="max-w-xl w-full  rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
      <h2 className="font-bold text-5xl text-headingBlack dark:text-neutral-200">
      KodeFeeeest!
      </h2>
      <p className="text-paraGray text-xl max-w-xl mt-1 dark:text-neutral-300">
      Registrations for kodefest not started yet, Meanwhile subscribe we will notify you !
      </p>

      <form className="my-4" onSubmit={handleSubmit}>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="name">Name</Label>
          <Input id="name" placeholder="name" type="text" />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input id="email" placeholder="projectmayhem@fc.com" type="email" />
        </LabelInputContainer>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="university">University</Label>
            <Input id="university" placeholder="MIT" type="text" />
          </LabelInputContainer>
          <LabelInputContainer>
            <Label htmlFor="branch">Branch</Label>
            <Input id="branch" placeholder="CSE" type="text" />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="phone">Phone Number</Label>
          <Input id="phone" placeholder="+919087654321" type="phone" />
        </LabelInputContainer>
        <div className="flex items-center space-x-2 mb-4">
        <Checkbox id="terms" />
        <Label htmlFor="terms">Accept terms and conditions</Label>
      </div>

        <button
          className="bg-themepruple text-white  dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full  rounded-md h-10 font-medium "
          type="submit"
        >
          Register Now !
          <BottomGradient />
        </button>

        
      </form>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
