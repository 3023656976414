import React, { useRef, useEffect } from 'react';
import kampkode_certificate from './assets/kampkode_certificate.png'; // Import the template image
import { postCertificate } from '../../services/certificate.service'; // Import the postCertificate function

interface CertificateProps {
  id: string;
  userName: string;
  course: string;
  date: string;
}

const CertificateGenerator: React.FC<CertificateProps> = ({ id, userName, course, date }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // Function to scale the image to a maximum size (e.g., 1080px width, 720px height)
  const scaleImage = (img: HTMLImageElement, canvas: HTMLCanvasElement, maxWidth: number, maxHeight: number) => {
    const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
    const width = img.width * ratio;
    const height = img.height * ratio;
    canvas.width = width;
    canvas.height = height;
    return { width, height };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const certificateImage = new Image();
        certificateImage.src = kampkode_certificate; // Use imported image

        certificateImage.onload = () => {
          // Compress by resizing the image to a maximum of 1080x720
          const { width, height } = scaleImage(certificateImage, canvas, 893, 644);

          // Draw the resized certificate template
          ctx.drawImage(certificateImage, 0, 0, width, height);

          // Define text styles
          const nameFontSize = 60; // Adjust font size for smaller canvas
          const courseFontSize = 40;
          const dateFontSize = 18;

          ctx.fillStyle = 'black';

          // Set font and write date
          ctx.font = `${dateFontSize}px Arial`;
          ctx.fillText(date, 230, canvas.height * 0.37); // Coordinates for date

          // Set font and write name
          ctx.font = `${nameFontSize}px Arial`;
          ctx.textAlign = 'left';
          ctx.fillText(userName, 226, canvas.height * 0.445); // Coordinates for name

          // Set font and write course
          ctx.font = `${courseFontSize}px Arial`;
          ctx.fillText(course, 230, canvas.height * 0.55); // Coordinates for course

          // Convert the canvas to Base64 with JPEG compression
          const base64Certificate = canvas.toDataURL('image/webp', 0.50); // Reduce quality to 70%

          // Post the certificate data to Firebase via the backend
          postCertificate({
            id,
            userName,
            course,
            date,
            base64Image: base64Certificate // Store the compressed image
          })
          .then(response => {
            console.log('Certificate indexed successfully:', response);
            console.log('certificateID: ',id)
          })
          .catch(error => {
            console.error('Error posting certificate:', error);
          });
        };
      }
    }
  }, [id, userName, course, date]);

  return (
    <div className="flex justify-center items-center mt-8">
      <canvas
        ref={canvasRef}
        className="border shadow-lg rounded-lg"
        style={{ width: '893px', height: 'auto' }} // Small window for the certificate preview
      />
    </div>
  );
};

export default CertificateGenerator;
