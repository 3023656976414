import React from 'react';
import ProjectHero from './ProjectHero';
import ProjectIntro from './ProjectIntro';
import MoreProjects from './MoreProjects';
import { useNavigate, useParams } from 'react-router-dom';
import projects from '../../../data/ProjectData.json';

interface ProjectParams extends Record<string, string | undefined> {
  category: string; 
  projectName: string;
}

const ProjectDetail = () => {
  const { category,  projectName } = useParams<ProjectParams>();

  // Decode the project name if it exists and contains URL-encoded characters like %20 for spaces
  const decodedProjectName = projectName ? decodeURIComponent(projectName) : '';

  // Access the project data dynamically, with fallback checks for undefined values
  // console.log('URL THINGS')
  // console.log(`category ${category}`)
  // console.log(`sub category ${subCategory}`)
  // console.log(`project name ${projectName}`)
  // console.log(`decoded project name  ${decodedProjectName}`)
  function getProjectData(category :string, projectName : string) {
    //@ts-ignore
    const projectData = projects[category];
  
    if (projectData) {
      // Check if the project is directly in the category
      if (projectData[projectName]) {
        return projectData[projectName];
      }
  
      // Check if the project is in a subcategory
      for (const subcategory in projectData) {
        if (projectData.hasOwnProperty(subcategory) && projectData[subcategory][projectName]) {
          return projectData[subcategory][projectName];
        }
      }
    }
  
    return null; // If project not found, return null
  }
  
// const testingProject = getProjectData('WebDevelopment', 'Unit Testing');
//   console.log('TESTING PROJECT')
//   console.log(testingProject)
  
  const projectData = getProjectData(category!,decodedProjectName)
  // console.log('GOT PARAMS PROJECT DATA')
  // console.log(projectData)
  // Handle the case where no project data is found
  if (!projectData) {
    return <NotFound />;
  }

  return (
    <div className='mx-7 md:mx-12 lg:mx-20'>
      <ProjectHero title={projectData.title} description={projectData.description} category={category!}/>
      <ProjectIntro
        title={projectData.title}
        intro={projectData.intro}
        projectFeatures={projectData.projectFeatures}
        userExperience={projectData.userExperience}
        softwareAndTools={projectData.softwareAndTools}
        techStack={projectData.techStack}
        conclusion={projectData.conclusion}
      />
      <MoreProjects />
    </div>
  );
};

export default ProjectDetail;

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='h-screen flex flex-col gap-3 items-center justify-center'>
      <h1 className='text-2xl font-semibold'>404 - Project not found</h1>
      <button
        className='px-5 py-1 bg-themepruple rounded-lg text-white text-xl font-medium'
        onClick={() => navigate('/projects#display')}
      >
        Back
      </button>
    </div>
  );
};
