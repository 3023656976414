import React, { useState } from 'react';
import CategoryScroll from './CategoryScroll';
import SubCategoryList from './SubCategoryList';
import DisplayCards from './DisplayCards';
import { ProjectDataType,Project } from '@/types/types';


const DesktopCarousel = ({ Data,cta }: { Data: ProjectDataType,cta:string }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('WebDevelopment');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);

  const categories = Object.keys(Data); // Get top-level categories
  const subCategories = selectedCategory ? Object.keys(Data[selectedCategory]) : [];

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null); // Reset selected subcategory when category changes
  };

  // Gather all projects from the selected category and subcategory
  const getProjects = (): Project[] => {
    if (selectedSubCategory) {
      // If a subcategory is selected, return only its projects
      return Object.values(Data[selectedCategory][selectedSubCategory]);
    } else {
      // If no subcategory is selected, return all projects from the selected category
      return Object.values(Data[selectedCategory]).flatMap(subCat => Object.values(subCat));
    }
  };

  const projects = getProjects(); // Get the projects based on the selection
  // console.log('projects are in DESKTOP CAROUSEL')
  // console.log(projects)
  

  return (
    <div className="w-full">
      <CategoryScroll
        categories={categories}
        selectedCategory={selectedCategory}
        onCategoryClick={handleCategoryClick}
      />
      {selectedCategory && (
        <SubCategoryList
          subCategories={subCategories}
          selectedSubCategory={selectedSubCategory}
          onSubCategoryClick={setSelectedSubCategory}
        /> 
      )}
      <DisplayCards category={selectedCategory} Projects={projects} cta={cta}/> 
    </div>
  );
};

export default DesktopCarousel;