export const Project_Faq_Data = [
    {
      question: "What types of projects does KampKode offer?",
      answer:
        "We offer project opportunities across various sectors and industries, providing students with valuable real-world experience. To explore all available domains, you can view our comprehensive project catalog.",
    },
    {
      question: "Will I receive project documentation and code access?",
      answer:
        "Yes, participants receive full access to source code, project documentation, and guided presentations.",
    },
    {
      question: "Are these projects industry-relevant?",
      answer:
        "All projects are aligned with current industry trends, providing valuable hands-on experience.",
    },
    {
      question: "What support is available during the project?",
      answer:
        "Our team is available for live sessions, answering queries and providing detailed feedback on progress.",
    },
    {
      question: "Do we get certificates?",
      answer:
        "Yes, a completion certificate is provided for all participants.",
    },
    {
      question: "How can I showcase my project work?",
      answer:
        "We assist with project presentations and LinkedIn profile optimization.",
    },
  ];
  