import {Logos} from '../../data/Logos'

const perks =[
    {
        Icon : Logos.LinkedInRounded,
        title:'LinkedIn Profile Optimization',
        description:'We provide expert LinkedIn strategies to boost your carrer opportunities.'
    },
    
    {
        Icon : Logos.PdfIcon,
        title : 'Resume Building with LaTeX',
        description:'We help students create polished, impactful resumes using LaTeX.'
    },
    {
        Icon : Logos.WebIconRounded,
        title:'Regular Job Alerts',
        description:'Students receive regular job updates,keeping them informed and ready to apply'
    },
    {
        Icon : Logos.SheildIcon,
        title:'Guaranteed Internship',
        description:'Top performer get guaranteed internships for hands-on experience'

    
    }
]
const CRTPerks = () => {
  return (
    <div className='flex flex-wrap lg:px-20 md:px-14 px-7 gap-3 gap-y-14 py-20 bg-white'>
        {perks.map((p, i) =>
        <Card key={i} Icon={p.Icon} title={p.title} description={p.description}/>
    )}
    </div>
  )
}

interface CardProps {
    Icon: React.ElementType;
    title :string;
    description : string;
}
const Card = ({Icon,title,description}:CardProps) => (
    <div className='bg-[#F9FAFB] text-center px-5 py-7 relative shadow-lg w-64 rounded-md mx-auto'>
        <span className='absolute top-0 -translate-y-1/2 -translate-x-1/2'><Icon/></span>
        <h1 className='text-headingBlack font-semibold text-xl py-5'>{title}</h1>
        <p className='text-[#475467] text-lg font-normal'>{description}</p>
    </div>
)

export default CRTPerks