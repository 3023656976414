import React, { useState } from 'react';
import Papa from 'papaparse';
import CertificateGenerator from './GenerateCerificate';
import { v4 as uuidv4 } from 'uuid';

interface CertificateData {
  certificateID: string;
  userName: string;
  course: string;
  date: string;
}

const CertificateForm: React.FC = () => {
  const [csvData, setCsvData] = useState<CertificateData[]>([]);
  const [bulkCertificates, setBulkCertificates] = useState<CertificateData[]>([]);
  const [processing, setProcessing] = useState(false);

  // Handle CSV upload and parse using PapaParse
  const handleCSVUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results: any) => {
          setCsvData(results.data);
        },
      });
    }
  };

  // Function to handle bulk certificate generation
  const generateCertificatesBulk = async () => {
    setProcessing(true);

    const certificates: CertificateData[] = [];

    for (const data of csvData) {
      const { userName, course, date } = data;

      // Generate certificateID (UUID)
      const certificateID = uuidv4();

      certificates.push({
        certificateID,
        userName,
        course,
        date,
      });
    }

    setBulkCertificates(certificates);
    setProcessing(false);
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-6">
      <h1 className="text-3xl font-bold mb-4">Bulk Certificate Generator</h1>

      {/* CSV Upload */}
      <input type="file" accept=".csv" onChange={handleCSVUpload} className="mb-4" />

      <button
        onClick={generateCertificatesBulk}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        disabled={processing || csvData.length === 0}
      >
        {processing ? 'Generating...' : 'Generate Bulk Certificates'}
      </button>

      {/* Render Generated Certificates */}
      {bulkCertificates.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Generated Certificates</h2>
          <div className="grid grid-cols-1 gap-4">
            {bulkCertificates.map((certificate, idx) => (
              <CertificateGenerator
                id={certificate.certificateID}
                userName={certificate.userName}
                course={certificate.course}
                date={certificate.date}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificateForm;
