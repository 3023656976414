import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, MapPin, ArrowUpRight } from "lucide-react";
import { Job } from '@/types/types';


interface ClientJobCardProps {
  job: Job;
}

const ClientJobCard: React.FC<ClientJobCardProps> = ({ job }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleJobUpdateClick = (jobId: string) => {
    navigate(`/job-updates/${jobId}`);
  };

  return (
    <>
      {isMobile ? (
        <ClientJobCardMobile job={job} onViewApply={handleJobUpdateClick} />
      ) : (
        <ClientJobCardDesktop job={job} onViewApply={handleJobUpdateClick} />
      )}
    </>
  );
};

// Desktop component
const ClientJobCardDesktop: React.FC<{ job: Job; onViewApply: (jobId: string) => void }> = ({ job, onViewApply }) => (
  <div className="border flex flex-row items-center w-full md:w-[1080px] h-auto md:h-[184px] rounded-md p-4 hover:bg-[#f8f8f8]">
    <div className="w-[87px] h-[87px] flex-shrink-0 flex items-center mb-4 p-2">
        <img src={job.companyLogo} alt="company logo" className="w-full" />
    </div>
    <div className="flex flex-col justify-around w-full p-4">
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div>
          <div className="text-lg font-bold">{job.jobTitle}</div>
          <div className="text-sm font-semibold text-[#5133A0]">
            {job.companyName}
          </div>
        </div>
        <div className="mt-2 md:mt-0">
        <button
            className="bg-[#7F56D9] text-white px-4 py-2 rounded-md flex items-center gap-1 transition-colors duration-200 hover:bg-[#6f47c0]"
            onClick={() => onViewApply(job.id)}
          >
            Apply
            <ArrowUpRight />
          </button>
        </div>
      </div>
      <div
        className="text-sm text-[#475467] mt-2 overflow-hidden"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2, // Always clamp to 2 lines
          WebkitBoxOrient: 'vertical',
          maxHeight: '3em', // Ensure this height is enough for two lines
        }}
      >
        {job.shortDescription ? job.shortDescription : "No description available."} {/* Fallback text */}
      </div>



      <div className="flex flex-wrap gap-4 mt-4">
        <div className="flex gap-1 text-[#475467] font-bold">
          <MapPin className="text-slate-400" />
          {job.location}
        </div>
        <div className="flex gap-1 text-[#475467] font-bold">
          <Clock className="text-slate-400" />
          {job.employment}
        </div>
      </div>
    </div>
  </div>
);

// Mobile component
const ClientJobCardMobile: React.FC<{ job: Job; onViewApply: (jobId: string) => void }> = ({ job, onViewApply }) => (
  <div className="border flex flex-col w-full p-4 rounded-lg">
    <div className="flex items-center mb-4">
      <div className="w-[87px] h-[87px] flex-shrink-0 flex items-center p-2">
        <img src={job.companyLogo} alt="company logo" className="w-full" />
      </div>
      <div className="flex flex-col ml-4">
        <div className="text-lg font-bold">{job.jobTitle}</div>
        <div className="text-sm font-semibold text-[#5133A0]">{job.companyName}</div>
      </div>
    </div>

    
      <div
          className="text-sm text-[#475467] mt-2 overflow-hidden"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2, // Always clamp to 2 lines
              WebkitBoxOrient: 'vertical',
              maxHeight: '3em', // Ensure this height is enough for two lines
            }}
          >
            {job.shortDescription ? job.shortDescription : "No description available."} {/* Fallback text */}
      </div>
    
    <div className="flex flex-wrap gap-4 mt-4">
      <div className="flex gap-1 text-[#475467] font-bold">
        <MapPin className="text-slate-400" />
        {job.location}
      </div>
      <div className="flex gap-1 text-[#475467] font-bold">
        <Clock className="text-slate-400" />
        {job.employment}
      </div>
    </div>

    <button
        className="bg-[#7F56D9] text-white mt-4 w-full px-4 py-2 rounded-md flex items-center justify-center gap-2 transition-colors duration-200 hover:bg-[#6f47c0]"
        onClick={() => onViewApply(job.id)}
      >
        Apply 
        <ArrowUpRight />
      </button>
  </div>
);


export default ClientJobCard;
