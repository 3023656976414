
import React, { useEffect } from 'react'
import ProjectsHero from './ProjectsHero'
import { ProjectsImpact } from './ProjectsImpact'
import { ProjectsFeedback } from './ProjectsFeedback'
import ProjectsDispaly from './ProjectsDispaly'
import { Project_Faq_Data } from '../../../data/ProjectFAQ'
import FAQ from '../../FAQ'

const ProjectsPage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div>

    <div className='mx-8 md:mx-20 my-5'>
        <ProjectsHero/>
        <div id='display'>
        <ProjectsDispaly/>
        </div>
        <ProjectsImpact/>
        <ProjectsFeedback/>
      
    </div>
    <FAQ data={Project_Faq_Data}/>
    </div>
  )
}

export default ProjectsPage
