import React from 'react';

// Skeleton for desktop view
export const JobDetailsSkeletonDesktop: React.FC = () => (
  <div className="rounded-md p-6 bg-white relative m-20 animate-pulse">
    <div className="flex items-center mb-6">
      <div className="w-32 h-32 bg-gray-300 mr-4 rounded-md" />
      <div className="flex flex-col">
        <div className="w-32 h-6 bg-gray-300 rounded-md mb-2" />
        <div className="w-64 h-12 bg-gray-300 rounded-md mb-4" />
        <div className="flex space-x-4">
          <div className="w-24 h-4 bg-gray-300 rounded-md" />
          <div className="w-24 h-4 bg-gray-300 rounded-md" />
        </div>
      </div>
    </div>
    <div className="w-full h-24 bg-gray-300 rounded-md mb-6" />
    <div className="w-full h-10 bg-gray-300 rounded-md mb-4" />
    <div className="w-full h-10 bg-gray-300 rounded-md" />
  </div>
);


// Skeleton for mobile view
export const JobDetailsSkeletonMobile: React.FC = () => (
  <div className="p-4 mt-20 animate-pulse">
    <div className="flex items-center mb-4">
      <div className="w-16 h-16 bg-gray-300 mr-4 rounded-md" />
      <div className="flex flex-col space-y-2">
        <div className="w-24 h-4 bg-gray-300 rounded-md" />
        <div className="w-40 h-6 bg-gray-300 rounded-md" />
        <div className="w-28 h-4 bg-gray-300 rounded-md" />
        <div className="flex space-x-4">
          <div className="w-12 h-4 bg-gray-300 rounded-md" />
          <div className="w-12 h-4 bg-gray-300 rounded-md" />
        </div>
      </div>
    </div>
    <div className="w-full h-24 bg-gray-300 rounded-md mb-4" />
    <div className="w-full h-10 bg-gray-300 rounded-md mb-2" />
    <div className="w-full h-10 bg-gray-300 rounded-md" />
  </div>
);

export const JobUpdatesSkeletonDesktop: React.FC = () => {
    return (
      <div className="animate-pulse border flex flex-row items-center w-full md:w-[1080px] h-auto md:h-[184px] rounded-md p-4">
        <div className="w-[87px] h-[87px] bg-gray-300 rounded-md flex-shrink-0 flex items-center mb-4 p-2">
          {/* Placeholder for company logo */}
        </div>
        <div className="flex flex-col justify-around w-full p-4">
          <div className="flex flex-col md:flex-row justify-between w-full">
            <div>
              <div className="h-6 bg-gray-300 rounded-md w-40 mb-2"></div> {/* Placeholder for job title */}
              <div className="h-4 bg-gray-200 rounded-md w-24"></div> {/* Placeholder for company name */}
            </div>
            <div className="mt-2 md:mt-0">
              <div className="h-8 w-28 bg-gray-300 rounded-md"></div> {/* Placeholder for View & Apply button */}
            </div>
          </div>
          <div className="h-4 bg-gray-200 rounded-md mt-2 w-full"></div> {/* Placeholder for job description line 1 */}
          <div className="h-4 bg-gray-200 rounded-md mt-2 w-full"></div> {/* Placeholder for job description line 2 */}
          <div className="flex flex-wrap gap-4 mt-4">
            <div className="h-4 w-32 bg-gray-300 rounded-md"></div> {/* Placeholder for location */}
            <div className="h-4 w-32 bg-gray-300 rounded-md"></div> {/* Placeholder for employment type */}
          </div>
        </div>
      </div>
    );
  };

  export const JobUpdatesSkeletonMobile: React.FC = () => {
    return (
      <div className="animate-pulse border flex flex-col w-full p-4 rounded-lg">
        <div className="flex items-center mb-4">
          <div className="w-[87px] h-[87px] bg-gray-300 rounded-md flex-shrink-0 p-2">
            {/* Placeholder for company logo */}
          </div>
          <div className="flex flex-col ml-4">
            <div className="h-6 bg-gray-300 rounded-md w-40 mb-2"></div> {/* Placeholder for job title */}
            <div className="h-4 bg-gray-200 rounded-md w-24"></div> {/* Placeholder for company name */}
          </div>
        </div>
  
        <div className="h-4 bg-gray-200 rounded-md mt-2 w-full"></div> {/* Placeholder for job description line 1 */}
        <div className="h-4 bg-gray-200 rounded-md mt-2 w-full"></div> {/* Placeholder for job description line 2 */}
  
        <div className="flex flex-wrap gap-4 mt-4">
          <div className="h-4 w-32 bg-gray-300 rounded-md"></div> {/* Placeholder for location */}
          <div className="h-4 w-32 bg-gray-300 rounded-md"></div> {/* Placeholder for employment type */}
        </div>
  
        <div className="h-8 bg-gray-300 rounded-md mt-4 w-full"></div> {/* Placeholder for View & Apply button */}
      </div>
    );
  };