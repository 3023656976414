import CarouselComponent from '../../customCarousel/CarouselComponent'
import React from 'react'
import  ProjectData  from '../../../data/ProjectData.json'
// import { ProjectDataType } from '@/types/types'

// console.log('got data in project dispaly')
// const projects : ProjectDataType = ProjectData;
// console.log(projects)
const ProjectsDispaly = () => {
  return (
    <div className='bg-[#F9FAFB] w-full my-2 rounded-md py-6'>
        <div className="w-full py-8 lg:py-14 px-3">
            <h1 className="text-[#101828] text-xl md:text-2xl lg:text-4xl font-semibold my-3">Turn Concepts into Career-Defining Projects</h1>
            <p className="text-[#475467] text-base md:text-xl font-normal">Gain real-world skills and build a standout portfolio</p>
        </div>
        <div>
          <CarouselComponent Data={ProjectData} cta='View Project'/>
      </div>
    </div>
  )
}

export default ProjectsDispaly