import React from 'react'
const CRTIntro = () => {
  return (
    <div className='md:mt-52 bg-white'>
        <div className='w-full md:w-2/4 mx-auto md:py-12 md:my-3 px-10'>

            <h1 className='text-3xl font-semibold border-t py-5'>Introduction</h1>
            <p className='pb-3'>We offer a comprehensive program with 100+ hours of interactive training, hands-on projects, resume crafting, LinkedIn optimization, and exclusive job alerts. Our dedicated instructors provide personalized guidance to help students transition smoothly into the professional world. Join us to boost your employability and secure internships and job opportunities!</p>
            <div className='h-48 bg-amber-200'>
            </div>
        </div>

    </div>
  )
}

export default CRTIntro
