import React  from "react";
import { AccordionBox } from "./AccordionBox";

const FAQ = ({data}:any) => {
  return (
    <div className="h-auto font-Inter w-full md:mb-24 px-2 bg-white">
      <div className="bg-[#F2F2F2] w-screen py-5 px-10  lg:px-20 -ml-2 mt-0">
        <div className="flex flex-col md:flex-row items-start  md:justify-between md:mx-2 ">
          <div>
            <p className="text-[#6938EF] my-2 md:my-3">Support</p>
            <h1 className=" text-xl md:text-2xl lg:text-4xl font-semibold ">
              Not found what you need?
            </h1>
          </div>
          <p className="text-[#475467] font-normal md:ml-3 mt-4 lg:mt-14">
            Have questions? We're here to help.
          </p>
        </div>
        <div className="flex gap-2 flex-col md:flex-row mt-7 md:mx-2  items-center">
          <button className="px-5 py-3 bg-[#7F70ED] hover:bg-[#5a49dd] text-white font-medium rounded-md text-[14px] w-full  md:w-fit transition-all duration-300">
            Chat with our team
          </button>
          <button className="text-[#344054] font-medium px-5 py-3 border rounded-md text-[14px] w-full md:w-fit bg-white">
            Schedule a 1:1 call with founder
          </button>
        </div>
      </div>
      <div className="mx-7  md:mx-12 lg:mx-20 pt-8 md:pt-10 flex lg:flex-row flex-col justify-between lg:gap-48 gap-5 md:gap-10 bg-white">
        <div className="md:w-96 mx-2">
          <h1 className="text-[#6938EF] text-4xl font-semibold my-2 ">FAQ's</h1>
          <p className="text-md min-w-48">
            Everything you need to know about our services. Can’t
            find the answer you’re looking for? Please <span className="underline"> chat to our friendly team.
              </span>
          </p>
        </div>
        <div className="mr-3 bg-white">
          <AccordionBox data={data} />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
