const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";
  
  export const postJob = async (payload: {
    jobId?: string,
    applyLink: string,
    companyLogo: string,
    companyName: string,
    employment: string,
    jobDescription: string,
    jobTitle: string,
    location: string 
}) => {
    const response = await fetch(`${baseUrl}jobs`, {
        headers: {
            "Content-Type": "application/json",
            "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
        },
        method: "POST",
        body: JSON.stringify(payload),
    });

    // Check if the response is JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
        return response.json();
    } else {
        const text = await response.text();
        throw new Error(`Unexpected response: ${text}`);
    }
};

