import { useEffect } from 'react';

const useDisableShortcuts = () => {
  // Check the environment using REACT_APP_ENV
  const isProduction = process.env.REACT_APP_ENV
    ? true
    : false; // You can expand this logic if needed

  useEffect(() => {
    if (isProduction) {
      // Disable right-click
      const disableRightClick = (event: MouseEvent) => {
        event.preventDefault();
      };

      // Disable specific keyboard shortcuts
      const disableShortcuts = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 's') {
          event.preventDefault(); // Disable Ctrl+S
        }
        if (event.ctrlKey && event.key === 'u') {
          event.preventDefault(); // Disable Ctrl+U
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault(); // Disable Ctrl+Shift+I
        }
      };

      // Add event listeners
      document.addEventListener('contextmenu', disableRightClick);
      window.addEventListener('keydown', disableShortcuts);

      // Cleanup on component unmount
      return () => {
        document.removeEventListener('contextmenu', disableRightClick);
        window.removeEventListener('keydown', disableShortcuts);
      };
    }
  }, [isProduction]); 
  console.log("Is Production : ",isProduction)
  // Dependencies for useEffect
};

export default useDisableShortcuts;
