import { ArrowLeft, ArrowRight } from 'lucide-react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Arrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Data = [
  {
    type: 'Web Development',
    title: 'Movie Recommendation System',
    image: '/movieRec.webp',
    description: 'The Movie Recommendation System uses machine learning to suggest movies based on your searches from a 5,000+ movie database',
    tags: [
      { label: 'React js', borderColor: '#dacaee', textColor: '#6941C6', bgColor: '#E9D7FE' },
      { label: 'HTML 5', borderColor: '#bbcaee', textColor: '#3538CD', bgColor: '#C7D7FE' },
      { label: 'Redux', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' }
    ]
  },
  {
    type: 'Web Development',
    title: 'Fintech',
    image: '/projectPage/Fintech/Fintech1.png',
    description: 'Become a part of our forward-thinking team as a Node.js Developer. Collaborate with industry experts and enhance your skills.',
    tags: [
      { label: 'Node js', borderColor: '#dacaee', textColor: '#6941C6', bgColor: '#E9D7FE' },
      { label: 'BootStrap', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' },
      { label: 'JavaScript', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' }
    ]
  },
  {
    type: 'Web Development',
    title: 'On Tkt',
    image: '/projectPage/OnTkt/OnTkt1.png',
    description: 'This bus conductor app enables passengers to buy tickets using cash or online payments and stores ticket details in a database.',
    tags: [
      { label: 'Software Development', borderColor: '#ABEFC6', textColor: '#067647', bgColor: '#ECFDF3' },
      { label: 'Tools', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' }
    ]
  },
  {
    type: 'CyberSec',
    title: 'Threat Discovery',
    image: '/Threat.webp',
    description: 'This project focuses on discovering and mitigating potential threats in real-time.',
    tags: [
      { label: 'React js', borderColor: '#dacaee', textColor: '#6941C6', bgColor: '#E9D7FE' },
      { label: 'HTML 5', borderColor: '#bbcaee', textColor: '#3538CD', bgColor: '#C7D7FE' },
      { label: 'Redux', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' }
    ]
  },
  {
    type: 'E-commerce Web Design',
    title: 'Movie Recommendation System',
    image: '/projectPage/E-Commerce/E-Commerce1.png',
    description: 'A comprehensive project focusing on designing intuitive and responsive e-commerce user interfaces using modern frontend technologies.',
    tags: [
      { label: 'React js', borderColor: '#dacaee', textColor: '#6941C6', bgColor: '#E9D7FE' },
      { label: 'HTML 5', borderColor: '#bbcaee', textColor: '#3538CD', bgColor: '#C7D7FE' },
      { label: 'Redux', borderColor: '#ecc1df', textColor: '#C11574', bgColor: '#FCCEEE' }
    ]
  }
];

const MoreProjects = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: -350, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: 350, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-[#EFEFEF] py-12 mt-8 px-7 md:px-12 lg:px-20 w-screen -ml-7 md:-ml-12 lg:-ml-20">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center px-2 my-3">
        <div>
          <h1 className="text-[#101828] font-semibold text-4xl">Related Projects</h1>
          <p className="text-[#475467] font-normal text-xl my-3">The latest news, technologies, and resources from our team.</p>
        </div>
        <button
          className="bg-[#7F56D9] h-16 lg:h-12 text-white px-5 md:py-0 lg:py-3 rounded-md"
          onClick={() => navigate('/projects#display')}
        >
          View all Projects
        </button>
      </div>
      <div className="flex gap-5 overflow-x-auto no-scrollbar px-2" ref={ref}>
        {Data.map((project) => (
          <ProjectCard key={project.title} {...project} />
        ))}
      </div>
      <div className="flex my-8 gap-8">
        <ArrowLeft className="carousel-arrows" onClick={scrollLeft} />
        <ArrowRight className="carousel-arrows" onClick={scrollRight} />
      </div>
    </div>
  );
};

export default MoreProjects;

interface ProjectCardProps {
  title: string;
  description: string;
  type: string;
  image: string;
  tags: { label: string; borderColor: string; textColor: string; bgColor: string }[];
}

const ProjectCard = ({ title, image, description, type, tags }: ProjectCardProps) => (
  <div className="hover:scale-[1.02] transition-all ease-in-out duration-200">
    <img src={image} alt={title} className="w-96 h-60 rounded-2xl" />
    <div className="my-5 px-1 w-80 md:w-96">
      <span className="text-[#6941C6] font-semibold text-base" id="type">
        {type}
      </span>
      <div className="flex items-center justify-between">
        <h1
          className="font-semibold text-2xl text-[#101828] my-2"
          id="project-title"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {title}
        </h1>
        <Arrow />
      </div>
      <p className="text-[#475467] text-base font-normal" id="project-description">
        {description}
      </p>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <TechTag key={index} label={tag.label} borderColor={tag.borderColor} textColor={tag.textColor} bgColor={tag.bgColor} />
        ))}
      </div>
    </div>
  </div>
);

type TechTagProps = {
  label: string;
  borderColor: string;
  textColor: string;
  bgColor: string;
};

const TechTag: React.FC<TechTagProps> = ({ label, borderColor, textColor, bgColor }) => (
  <div className="rounded-xl border px-2 py-1" style={{ borderColor, color: textColor, backgroundColor: bgColor }}>
    {label}
  </div>
);
