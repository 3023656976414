import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs } from '../../actions/jobActions';
import CleintJobCard from "./CleintJobCard";
import { JobUpdatesSkeletonDesktop, JobUpdatesSkeletonMobile } from "./JobDetailsSkeleton";  
import { RootState, AppDispatch } from '@/actions/store';
import { useNavigate } from 'react-router-dom';
import { User, Search } from "lucide-react"; 
import { isUserAdmin } from "../../uiHelper"; 
import { Job } from '@/types/types';

const SearchJobs: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { jobs, loading, hasMore, page, fetchFailed } = useSelector((state: RootState) => state.job);
  const user = useSelector((state: any) => state.currentUserReducer);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isTeacher, setIsTeacher] = useState<boolean>(false);
  
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastJobElementRef = useRef<HTMLDivElement | null>(null);
  
  const skeletonCount = isMobile ? 5 : 10; // Number of skeletons to show

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchJobsIfNeeded = useCallback(async () => {
    if (!loading && hasMore && !fetchFailed) {
      try {
        await dispatch(fetchJobs(page)); // Pass the current page
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    }
  }, [dispatch, page, loading, hasMore, fetchFailed]);

  useEffect(() => {
    if (!jobs.length) {
      fetchJobsIfNeeded(); // Initial fetch
    }
  }, [fetchJobsIfNeeded, jobs]);

  useEffect(() => {
    if (user && user.email) {
      setIsTeacher(isUserAdmin(user.email));
    }
  }, [user]);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !loading) {
        fetchJobsIfNeeded(); // Fetch more jobs
      }
    },
    [fetchJobsIfNeeded, hasMore, loading]
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (lastJobElementRef.current) {
      observerRef.current.observe(lastJobElementRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [handleObserver]);

  const filteredJobs = jobs.filter((job: Job) => {
    const regex = new RegExp(searchTerm, "i");
    return regex.test(job.jobTitle);
  });

  const renderSkeletons = () => {
    return Array.from({ length: skeletonCount }).map((_, index) => (
      <React.Fragment key={index}>
        {isMobile ? (
          <JobUpdatesSkeletonMobile />
        ) : (
          <JobUpdatesSkeletonDesktop />
        )}
      </React.Fragment>
    ));
  };

  const handleAdminButtonClick = () => {
    navigate('/job-updates/admin'); // Redirect to admin page
  };

  return (
    <div className="relative flex flex-col justify-center items-center px-4">
      <div className="mt-8 w-full">
        <div className="text-center">
          <div className="text-2xl md:text-3xl font-semibold">
            Job Alerts & Opportunities
          </div>
          <div className="text-gray-600 text-base md:text-lg tracking-wide mt-2">
            Stay updated with the latest job openings and career opportunities.
            <br />
            Your next career move starts here!
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-6 items-end md:w-[1080px]">
        {isTeacher && (
          <button onClick={handleAdminButtonClick} className="flex items-center bg-purple-500 text-white my-4 p-2 rounded-lg hover:bg-purple-600">
            <User className="mr-2" />
            Admin
          </button>
        )}
      </div>

      <div className="mt-4 w-full md:w-[1080px] flex justify-center">
        <div className="relative w-full md:w-1/2">
          <input
            type="text"
            placeholder="Search for job titles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border rounded-lg pl-10 pr-2" 
          />
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <Search className="h-4 w-4 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="mt-4 w-full md:w-[1280px] flex flex-col gap-6 items-center">
        {/* Render previously fetched jobs */}
        {filteredJobs.map((job: Job, index: number) => (
          <div
            key={job.id}
            ref={index === filteredJobs.length - 1 ? lastJobElementRef : null}
          >
            <CleintJobCard job={job} />
          </div>
        ))}

        {/* Show skeletons while fetching new jobs */}
        {loading && renderSkeletons()}
      </div>

      {!hasMore && !loading && <div className="text-center mt-4">No more jobs available</div>}
      {fetchFailed && !loading && (
        <div className="text-center mt-4 text-red-500">Failed to load jobs. Please try again later.</div>
      )}
    </div>
  );
};

export default SearchJobs;
