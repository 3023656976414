import { BenifitsCardIcons } from "./BenifitsCardIcons"
// import { AtomIcon,UserCheckIcon,HandHeartIcon,TargetIcon, CircleCheckIcon } from 'lucide-react'

export const BenifitsData = [
    {
    Icon: BenifitsCardIcons.AtomIcon,
    title: '30+ internships Domains',
    description: 'Gain experience in over 30 fields tailored to your interests and career goals.'
    },
    {
     Icon:BenifitsCardIcons.UserCheck,
     title: 'Recognized Certification and Offer Letter',
     description: 'Earn a nationally recognized certificate and receive an official offer letter, enhancing your resume and professional credibility.'
    },
    {
        Icon: BenifitsCardIcons.HeartHand,
        title: 'Hands-on Experience',
        description: 'Work on real-time projects with tasks and assignments that simulate actual job scenarios'
    },
    {
      Icon: BenifitsCardIcons.MessageCheck,
      title: 'Letter of Recommendation ( LOR )',
      description: 'Obtain a valuable letter of recommendation from our expert trainers to support your future job applications.'
    },
    {
      Icon: BenifitsCardIcons.Certificate,
      title: 'Free ATS Resume Templates Powered by LaTeX',
      description: 'Access professionally designed ATS-compatible resume templates to enhance your job applications.'
    },
    {
      Icon: BenifitsCardIcons.Target,
      title: 'Job Updates and Assistance',
      description: 'Stay informed about the latest job openings and receive assistance in securing employment'
    },

]
