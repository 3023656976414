export const Internhsips_FAQ_data = [
  {
    question: "What areas do your internships cover?",
    answer:
      "Our internships span multiple sectors like Web Development, AI, ML, IoT, UI/UX, and more. Explore our catalog for a complete list.",
  },
  {
    question: "Are there any mentorship or networking opportunities?",
    answer:
      "Yes, interns will receive guidance from industry professionals and access to networking opportunities to help grow their careers.",
  },
  {
    question: "Are the internships paid?",
    answer:
      "Some internships are paid, while others offer valuable industry experience and certification.",
  },
  {
    question: "What is the duration of the internships?",
    answer:
      "Most internships last between 1 to 3 months, depending on the project and role.",
  },
  {
    question: "Will I receive a certificate after completion?",
    answer:
      "Yes, you will receive an industry-recognized certification upon successful completion.",
  },
  {
    question: "Can I apply if I have no prior experience?",
    answer:
      "Absolutely! We welcome candidates from all skill levels.",
  },
];
