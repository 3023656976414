import React from 'react'
interface CustomButtonProps {
    link: string;
    title: string;
}
const CustomButton = ({ link, title }: CustomButtonProps) => {
    return (
        <div className='mt-3 md:mt-6 lg:mt-10'>
      <a href={link} className="px-5 py-2.5 relative rounded group overflow-hidden font-medium bg-purple-50 text-purple-600 inline-block">
    <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-purple-600 group-hover:h-full opacity-90"></span>
    <span className="relative group-hover:text-white font-semibold text-sm md:text-base lg:text-lg">{title}</span>
</a>
        </div>

    )
}

export default CustomButton;