import { cn } from "../../magicui/lib/utils";
import Marquee from "../../magicui/marquee";

const reviews = [
  {
    name: "Rohan",
    username: "@Rohan",
    body: "Oh man, this movie recommendation system is something different! I haven't seen anything like this before. Totally worth the money!",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
  {
    name: "Priya",
    username: "@Priya",
    body: "Seriously, I’m so impressed with the fintech project! This is next level. Hats off, really amazing!",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg",
  },
  {
    name: "Anjali",
    username: "@Anjali",
    body: "Wow! Just too good. The Ontkt ticket booking project has such a big impact; I wasn’t expecting this. Loved it!",
    img: 'https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg',
  },
  {
    name: "Amit",
    username: "@Amit",
    body: "Bro, the e-commerce store for shoes is top-notch stuff. Seeing work like this makes my heart happy!",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
  {
    name: "Sneha",
    username: "@Sneha",
    body: "I can’t believe it’s so good! The inventory management system totally won my heart. Kudos to the team!",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg",
  },
  {
    name: "Vikram",
    username: "@Vikram",
    body: "This is incredible! The overall functionality across all projects is amazing, totally impressive!",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
];

const firstRow = reviews.slice(0, 2);
const secondRow = reviews.slice(2, 4);
const thirdRow = reviews.slice(4, 6);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative h-48 md:h-40 w-40 md:w-48 lg:w-72 cursor-pointer overflow-hidden rounded-xl border p-5 shadow-md",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
      )}
    >
      <div className="flex flex-row items-center gap-2 ">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name} 
          </figcaption>
          <p className="text-xs text-gray-500 dark:text-gray-400">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

export function ProjectsFeedback() {
  return (
    <div>
      <div className="w-full text-center py-8 lg:py-16">
        <h1 className="text-[#101828] text-xl md:text-2xl lg:text-4xl font-semibold my-3">What Our Students Are Saying</h1>
        <p className="text-[#475467] text-base md:text-xl font-normal">Hear directly from the thousands who have benefited from KampKode.</p>
      </div>
      <div className="relative flex h-[500px] w-full flex-row items-center justify-center overflow-hidden bg-background">
        <Marquee pauseOnHover vertical className="[--duration:20s]">
          {firstRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </Marquee>
        <Marquee reverse pauseOnHover vertical className="[--duration:20s]">
          {secondRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </Marquee>
        <Marquee pauseOnHover vertical className="[--duration:20s] hidden md:flex">
          {thirdRow.map((review) => (
            <ReviewCard key={review.username} {...review} />
          ))}
        </Marquee>
        <div className="pointer-events-none absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white dark:from-background"></div>
        <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-white dark:from-background"></div>
      </div>
    </div>
  );
}
