// import React, { useState, ChangeEvent, FormEvent } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import axios from "axios";
// import { X, Edit } from "lucide-react";

// interface EditJobFormProps {
//     jobId: string;
//     formData: {
//         id: string;
//         date: string;
//         jobTitle: string;
//         companyName: string;
//         applyLink: string;
//         location: string;
//         employment: string;
//         companyLogo: string;
//         jobDescription: string;
//     };
//     closePopup: () => void;
//     onJobEdited: (updatedJob: EditJobFormProps['formData']) => void; // Add this prop to the interface
// }

// const EditJobForm: React.FC<EditJobFormProps> = ({ jobId, formData: initialFormData, closePopup, onJobEdited }) => {
//     const [formData, setFormData] = useState(initialFormData);

//     const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleEditorChange = (content: string) => {
//         setFormData({ ...formData, jobDescription: content });
//     };

//     const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
//         e.preventDefault();
//         try {
//             await axios.put(`http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/jobs/${jobId}`, formData);
//             onJobEdited(formData); // Call the onJobEdited function with updated job data
//             closePopup();
//         } catch (error) {
//             console.error("Error updating job:", error);
//         }
//     };

//     return (
//         <div className="fixed inset-0 flex items-center justify-center z-50">
//             <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl">
//                 <div className="flex justify-between items-center mb-2">
//                     <div className="flex items-center">
//                         <Edit />
//                     </div>
//                     <button
//                         className="text-gray-500 hover:text-gray-700"
//                         onClick={closePopup}
//                     >
//                         <X />
//                     </button>
//                 </div>
//                 <h1 className="text-xl font-semibold">Edit a Job Opportunity</h1>
//                 <p className="text-gray-600 mb-4">Ensure your job posting is accurate and up-to-date.</p>
//                 <form onSubmit={handleSubmit}>
//                     <div className="mb-1">
//                         <label className="block text-sm font-medium text-gray-700">Job Title*</label>
//                         <input
//                             type="text"
//                             name="jobTitle"
//                             value={formData.jobTitle}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                         />
//                     </div>
//                     <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Company Name*</label>
//                             <input
//                                 type="text"
//                                 name="companyName"
//                                 value={formData.companyName}
//                                 onChange={handleChange}
//                                 required
//                                 className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                             />
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Apply link*</label>
//                             <input
//                                 type="url"
//                                 name="applyLink"
//                                 value={formData.applyLink}
//                                 onChange={handleChange}
//                                 required
//                                 className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                             />
//                         </div>
//                     </div>
//                     <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Location*</label>
//                             <input
//                                 type="text"
//                                 name="location"
//                                 value={formData.location}
//                                 onChange={handleChange}
//                                 required
//                                 className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                             />
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700">Employment*</label>
//                             <select
//                                 name="employment"
//                                 value={formData.employment}
//                                 onChange={handleChange}
//                                 className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                             >
//                                 <option value="Full time">Full time</option>
//                                 <option value="Part time">Part time</option>
//                                 <option value="Contract">Contract</option>
//                             </select>
//                         </div>
//                     </div>
//                     <div className="mb-1">
//                         <label className="block text-sm font-medium text-gray-700">Company logo link*</label>
//                         <input
//                             type="url"
//                             name="companyLogo"
//                             value={formData.companyLogo}
//                             onChange={handleChange}
//                             required
//                             className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-sm font-medium text-gray-700">Job Description*</label>
//                         <Editor
//                             apiKey="v0dsmhnm9byper8j2wmznz009nc36bj6r9id55d81rmwafqf"
//                             value={formData.jobDescription}
//                             init={{
//                                 height: 300,
//                                 menubar: false,
//                                 plugins: [
//                                     "advlist autolink lists link image charmap print preview anchor",
//                                     "searchreplace visualblocks code fullscreen",
//                                     "insertdatetime media table paste code help wordcount",
//                                 ],
//                                 toolbar:
//                                     "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
//                             }}
//                             onEditorChange={handleEditorChange}
//                         />
//                     </div>
//                     <div className="flex justify-between">
//                         <button
//                             type="button"
//                             onClick={closePopup}
//                             className="mr-3 max-w-m inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                         >
//                             Back
//                         </button>
//                         <button
//                             type="submit"
//                             className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                         >
//                             Update Job
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default EditJobForm;
import React, { useState, ChangeEvent, FormEvent } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { X, Edit } from "lucide-react";

interface EditJobFormProps {
    jobId: string;
    formData: {
        id: string;
        date: string;
        postDate: string;
        jobTitle: string;
        companyName: string;
        applyLink: string;
        location: string;
        employment: string;
        companyLogo: string;
        jobDescription: string;
        shortDescription: string;
    };
    closePopup: () => void;
    onJobEdited: (updatedJob: EditJobFormProps['formData']) => void;
}

const EditJobForm: React.FC<EditJobFormProps> = ({ jobId, formData: initialFormData, closePopup, onJobEdited }) => {
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEditorChange = (content: string) => {
        setFormData({ ...formData, jobDescription: content });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axios.put(`http://127.0.0.1:5001/kodekamp-ecb6c/asia-south1/kampkode/jobs/${jobId}`, formData);
            onJobEdited(formData); // Notify parent component about the update
            closePopup();
        } catch (error) {
            console.error("Error updating job:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl">
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <Edit />
                    </div>
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={closePopup}
                    >
                        <X />
                    </button>
                </div>
                <h1 className="text-xl font-semibold">Edit a Job Opportunity</h1>
                <p className="text-gray-600 mb-4">Ensure your job posting is accurate and up-to-date.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-1">
                        <label className="block text-sm font-medium text-gray-700">Job Title*</label>
                        <input
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Name*</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Apply link*</label>
                            <input
                                type="url"
                                name="applyLink"
                                value={formData.applyLink}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Location*</label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Employment*</label>
                            <select
                                name="employment"
                                value={formData.employment}
                                onChange={handleChange}
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="Full time">Full time</option>
                                <option value="Part time">Part time</option>
                                <option value="Contract">Contract</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-1">
                        <label className="block text-sm font-medium text-gray-700">Company logo link*</label>
                        <input
                            type="url"
                            name="companyLogo"
                            value={formData.companyLogo}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Job Description*</label>
                        <Editor
                            apiKey="v0dsmhnm9byper8j2wmznz009nc36bj6r9id55d81rmwafqf"
                            value={formData.jobDescription}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </div>
                    <div className="flex justify-between">
                        <button
                            type="button"
                            onClick={closePopup}
                            className="mr-3 max-w-m inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Update Job
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditJobForm;
