import React from 'react'
import { Logos } from '../../data/Logos'

const CRTPlaced = () => {
  return (
    <div className='bg-[#53389E] text-white py-20'>
      <div className='text-center flex flex-col gap-4 md:w-1/2 mx-auto px-10 md:px-0 my-6 md:my-11 lg:my-16'>
        <span className='font-semibold'>Achievement</span>
        <h1 className='font-semibold text-4xl'>Our students placed at</h1>
        <p className='font-normal text-[#E9D7FE] text-xl'>
          Our students have successfully landed positions in top companies, all thanks to KampKode’s awesome training programs that get them ready for real-life challenges. Your future could be next!
        </p>
      </div>
      <div className='mx-auto w-3/4 h-fit mt-4 p-2'>
        <div className='md:w-96 h-full grid grid-cols-3 md:grid-cols-5 gap-y-4 gap-x-4 md:gap-x-10 justify-items-center place-content-center mx-auto '>
          <div className="w-14 h-14 flex items-center justify-center ">
            <Logos.BitBuckeIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center ">
            <Logos.ConfluenceIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center ">
            <Logos.CreativeCloudIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center ">
            <Logos.DropBoxIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.InterComIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.JiraIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.MailChipIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.StripeIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.SoundCloudIcon />
          </div>
          <div className="w-14 h-14 flex items-center justify-center">
            <Logos.ZapierIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CRTPlaced
