import React from 'react'
import msme from '../../assets/msme.png'
import mca from '../../assets/mca.png'
import aicte from '../../assets/aicte.png'
import startUp from '../../assets/startupind.png'
import apsche from '../../assets/apsche.webp'

const SocialProof = () => {
  return (
    <div className='flex mx-10 md:mx-14 lg:mx-24 flex-col'>
      <div className='w-full md:w-1/2 my-auto '>
      <div className='flex  my-2 '> 
        <div className='w-1/4 h-0.5 bg-gradient-to-r from-blue-200 to-blue-600 my-auto'></div> 
        <span className='uppercase text-blue-700 mx-3 font-semibold'>Setting the standard</span>
      </div>
        <h1 className=' py-2 text-xl md:text-2xl lg:text-4xl font-semibold '>We Are Reconized By </h1>
      </div>
      <div className='flex flex-wrap h-fit w-full py-10 px-3 md:px-8 gap-x-12 gap-5 md:gap-x-20 justify-center items-center'>
        <img src={msme} alt='msme logo' />  
        <img src={mca} alt='msme logo' />
        <img src={startUp} alt='msme logo' />
        <img src={aicte} alt='msme logo' />
        <img src={apsche} alt='apsche logo' className='h-36' />
      </div>



    </div>
  )
}

export default SocialProof
