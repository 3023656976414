import React, { useState, useEffect } from 'react';
import axios from 'axios';
import template from './assets/kampkode_certificate.png';

interface CertificateData {
  base64Image: string;
  userName: string;
  course: string;
  date: string;
}

const CertificateVerification: React.FC = () => {
  const [certificateId, setCertificateId] = useState<string>('');
  const [certificateData, setCertificateData] = useState<CertificateData | null>(null);
  const [error, setError] = useState<string>('');

  // Scroll to the certificate display section when certificateData is set
  useEffect(() => {
    if (certificateData) {
      const certificateDisplay = document.querySelector('.certificate-display');
      if (certificateDisplay) {
        certificateDisplay.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [certificateData]);

  const handleVerify = async () => {
    try {
      const response = await axios.get<CertificateData>(
        `https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/certificate/${certificateId}`
      );
      setCertificateData(response.data);
      setError(''); // Clear any previous error
    } catch (err) {
      setError('Certificate not found. Please check the Certificate ID.');
      setCertificateData(null); // Clear any previous certificate data
    }
  };

  const handleDownload = () => {
    if (certificateData) {
      const link = document.createElement('a');
      link.href = `${certificateData.base64Image}`;
      link.download = `${certificateData.userName}_Certificate.png`;
      link.click();
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col items-center sm:px-6 lg:px-24 py-10 relative overflow-x-hidden lg:mb-12">
        {/* Purple Section */}
        <div className="bg-purple-700 text-white py-20 px-6 sm:px-12 lg:px-24 w-full flex flex-col items-center lg:rounded-3xl relative z-0 lg:mt-10 sm:mt-8">
          <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold mb-4 text-center">Verify Your Kampkode Certification</h1> <br />
          <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold mb-4 text-center">Certification</h1>

          <p className="text-base sm:text-lg mb-8 text-center">
            The sweat and hard work you’ve put into yourself is just a click away.
          </p>
          <div className="flex flex-col items-center mb-12 sm:mb-20 w-full">
            <input
              type="text"
              className="p-3 sm:p-4 w-full max-w-md rounded-md text-gray-900 focus:outline-none"
              placeholder="Enter Certificate ID"
              value={certificateId}
              onChange={(e) => setCertificateId(e.target.value)} 
            />
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <button
              className="bg-purple-900 text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md mt-4"
              onClick={handleVerify}
            >
              Verify Certificate
            </button>
            <p className="text-xs sm:text-sm mt-4">
              We care about the protection of your data. Read our{' '}
              <a href="/privacy" className="underline">privacy policy</a>.
            </p>
          </div>
        </div>

        {/* Background Image Section */}
        <div className="absolute w-full h-auto top-[60%] left-0 transform -translate-y-1/2 z-10 pointer-events-none">
          <img
            className="lg:w-full lg:h-auto sm:h-[200%] w-full"
            src={require('./assets/certificateBackground.svg').default}
            alt="Certificate Background" 
          />
        </div>

        {/* Certificate Display Section */}
        <div className="mt-8 sm:mt-[-150px] w-full sm:w-4/5 z-20 mx-auto relative certificate-display">
          <div className="mt-24 sm:mt-10 md:mt-10">
            <div className="flex justify-center overflow-auto lg:max-h-[720px]">
              <img
                src={certificateData ? `${certificateData.base64Image}` : `${template}`} 
                alt="Certificate"
                className="rounded-lg shadow-md w-[90%] sm:w-4/5 max-h-[720px]" 
              />
            </div>

            {certificateData && (
              <div className="mt-4 flex justify-center">
                <button
                  className="bg-purple-900 text-white px-6 py-2 rounded-md"
                  onClick={handleDownload}
                >
                  Download Certificate
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Inline Styles for Responsiveness */}
      <style>
        {`
          @media (max-width: 640px) {
            .absolute {
              top: 25%; /* Adjust based on the layout needs */
            }
            .certificate-display {
              margin-top: 0; /* Resetting margin to ensure no overlap */
            }
          }
        `}
      </style>
    </>
  );
};

export default CertificateVerification;
