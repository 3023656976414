import React from 'react';

const PrivacyPolicy = () => {
  const sections1 = [
    { title: 'What information do we collect? ', content: 'We collect personal information directly from you, such as your name, email address, phone number, and project details when you engage with our services. Additionally, we gather data on how you use our website, such as browsing behavior, device type, and interaction patterns through cookies and similar technologies. If you link third-party platforms (such as social media), we may also collect relevant information from these services.' },
    { title: 'How we collect your data?', content: 'We collect data when you interact with our website, subscribe to our services, or communicate with our team. We use tracking tools like cookies, web beacons, and session tracking to gather insights into your interactions with our platform. Some tracking technologies allow you to control the information collected by adjusting your browser settings or opting out of tracking where applicable.' },
  ];
  const sections2 = [
    { title: 'Who We Share Your Data With ?', content: 'We implement strict security measures to safeguard your data from unauthorized access, breaches, or disclosure. Our systems are regularly updated with advanced encryption and firewall technologies to mitigate potential risks. However, as with any online system, no security measures are foolproof. It is essential that you maintain the confidentiality of your passwords and notify us immediately of any suspicious activity on your account.' },
    { title: 'Your Privacy Rights ?', content: 'You have rights to access, correct, or delete your personal data. You can also opt out of certain data collection methods.' },
    { title: 'Jurisdiction-Specific Rules', content: 'If you are located in regions with specific privacy laws (such as the California Consumer Privacy Act or the General Data Protection Regulation in the EU), you may have additional rights regarding your data. This includes access, deletion, and information about how your data is shared. If you are outside of the United States, note that we may transfer your data to the U.S. and other jurisdictions where we operate.' },
    { title: 'Updates to This Privacy Policy', content: 'We may update this privacy policy from time to time to reflect changes in our services or regulatory obligations. When significant changes are made, we will notify you via email or through a prominent notice on our website. These changes become effective on the date posted.' },
  ];

  return (
    <div>
      <div className='bg-themepruple text-white h-80 w-full flex'>
        <div className='m-auto text-center px-10'>
          <p className='text-base font-medium'>Current as of 20 Sep 2024</p>
          <h1 className='text-2xl md:text-3xl lg:text-5xl font-semibold my-2'>Privacy Policy</h1>
          <p className='text-base pt-3 font-light'>We use some cookies to help improve your experience on this website. Here's some more info.</p>
        </div>
      </div>

      <div className='py-12 px-10 md:px-16 lg:px-32 xl:px-72'>
        <p className='mb-5'>
        At KampKode, your privacy and trust are important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our services. We are committed to safeguarding your data and ensuring transparency in how we handle it. By using our platform, you agree to the practices detailed below. Please take a moment to review this policy and contact us if you have any questions or concerns.
        </p>
        
        

        {sections1.map((section, index) => (
          <div className='mt-10' key={index}>
            <h1 className='font-medium text-2xl md:text-3xl my-3 capitalize text-headingBlack'>{section.title}</h1>
            <p className='mb-5 text-paraGray'>{section.content}</p>
          </div>
        ))}

        <div className=''>
          <h1 className='font-medium text-2xl md:text-3xl my-3 capitalize'>why we use your data?</h1>
        <p>We use your data to:</p>
        <ul className='list-disc ml-5 mt-2'>
          <li className='mb-2'>Provide and enhance our services, ensuring seamless user experiences.</li>
          <li className='mb-2'>Personalize your interaction with our platform, including project recommendations and relevant content.</li>
          <li className='mb-2'>Communicate with you about our services, promotions, updates, or issues.</li>
          <li className='mb-2'>Secure your data and prevent fraud or misuse.</li>
          <li className='mb-2 '>Improve our platform, gather insights through analytics, and perform research to understand usage trends.</li>
          <li className='mb-2'>Ful fill legal obligations and maintain the integrity of our services.</li>
        </ul>
        <p>We store your data only for as long as necessary to meet the purposes outlined in this policy or to comply with legal requirements. </p>
        </div>


        {sections2.map((section, index) => (
          <div className='mt-10' key={index}>
            <h1 className='font-medium text-2xl md:text-3xl my-3 capitalize text-headingBlack'>{section.title}</h1>
            <p className='mb-5 text-paraGray'>{section.content}</p>
          </div>
        ))}



        <div className=''>
          <h1 className='font-medium text-2xl md:text-3xl my-3 capitalize text-headingBlack'>Contact Us</h1>
          <p className='text-paraGray'>For any questions, concerns, or disputes regarding this privacy policy or how we handle your data, please contact us at: <br /> Email: [info@Kampkode.tech] <br /> Phone: [+91 85233 84666]</p>

        </div>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
