import React from 'react';

interface SubCategoryListProps {
  subCategories: string[];
  selectedSubCategory: string | null;
  onSubCategoryClick: (subCategory: string) => void;
}

const SubCategoryList: React.FC<SubCategoryListProps> = ({ subCategories, selectedSubCategory, onSubCategoryClick }) => {
  if (subCategories.length === 0) {
    return <div className="text-center">Coming Soon</div>;
  }

  return (
    <div className="relative mb-2">
      <div className="flex gap-2 overflow-x-auto py-2 no-scrollbar px-4 whitespace-nowrap">
        {subCategories.map((subCategory) => (
          <div
            key={subCategory}
            className={`px-2 py-1 border rounded-md capitalize cursor-pointer ${
              selectedSubCategory === subCategory
                ? 'bg-white shadow-lg rounded-lg font-semibold opacity-100'
                : 'hover:bg-white hover:shadow-sm opacity-75'
            }`}
            onClick={() => onSubCategoryClick(subCategory)}
          >
            {subCategory}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubCategoryList;
