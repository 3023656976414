import React from 'react'
import { HeroParallax } from '../../ui/hero-parallax';
const ProjectsHero = () => {
  return (
    <HeroParallax products={products} />
  )
}

export default ProjectsHero


export const products = [
    {
      title: "Fintech",
      link: "",
      thumbnail:
        "./projectPage/ProjectsBg/Fintech.png",
    },
    {
      title: "Artistify",
      link: "",
      thumbnail:
        "/projectPage/ProjectsBg/Artistify.png",
    },
    {
      title: "image3",
      link: "",
      thumbnail:
        "./projectPage/ProjectsBg/image3.png",
        
    },
   
    {
      title: "movies",
      link: "",
      thumbnail:
      "./projectPage/ProjectsBg/movies.png",

    },
    {
      title: "ontkt",
      link: "",
      thumbnail:
      "./projectPage/ProjectsBg/ontkt.png",
    },
    {
      title: "predict",
      link: "",
      thumbnail:
        "./projectPage/ProjectsBg/predict.png",
      },
      
      {
        title: "weather",
        link: "",
        thumbnail:
        "./projectPage/ProjectsBg/weather.png",
        
    },
    {
      title: "Aceternity UI",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/aceternityui.png",
    },
    {
      title: "Tailwind Master Kit",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/tailwindmasterkit.png",
    },
    {
      title: "SmartBridge",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/smartbridge.png",
    },
    {
      title: "Renderwork Studio",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/renderwork.png",
    },
   
    {
      title: "Creme Digital",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/cremedigital.png",
    },
    {
      title: "Golden Bells Academy",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/goldenbellsacademy.png",
    },
    {
      title: "Invoker Labs",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/invoker.png",
    },
    {
      title: "E Free Invoice",
      link: "",
      thumbnail:
        "https://aceternity.com/images/products/thumbnails/new/efreeinvoice.png",
    },
  ];