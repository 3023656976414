import { TrendingUp } from 'lucide-react'
import React from 'react'
import NumberTicker from '../magicui/number-ticker'

const CRTStatus = () => {
  return (
    <div className='px-7 md:px-14 lg:px-20 bg-white'>
        <div className='flex flex-col md:flex-row justify-between  px-1 py-5'>
            <div>
                <h1 className='font-semibold text-xl md:text-2xl lg:text-4xl text-[#101828] pb-1'>Navigating Our Growth Story</h1>
                <p className='text-[#475467]'>Metrics that capture our evolution and impact.</p>
            </div>
            <div className='flex flex-col md:flex-row gap-1 mt-2 md:mt-0'>
                <button className='px-3 gap-1 py-2 border rounded-md flex items-center justify-center h-fit'><TrendingUp/> Boost Your Skills</button>
                <a href='/crt#pricing'>
                <button className='px-3 py-2 text-white bg-themepruple h-fit border rounded-md'>Join Now</button>
                </a>
            </div>
        </div>
        <div className='h-fit lg:h-60  flex flex-col lg:flex-row  text-themepruple items-center justify-center md:justify-between rounded-lg py-12 w-full  mx-auto gap-y-10 text-center md:text-start'>
          <div className='flex flex-col w-56  md:w-60'>
            <NumberTicker value={4000}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Students Trained</p>
            <p className='text-[#475467]'>Empowering countless students for career success.</p>
          </div>
          <div className='flex flex-col  w-56  md:w-60 '>
            <NumberTicker value={600}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Projects Completed</p>
            <p className='text-[#475467]'>Hands-on experience from numerous projects.</p>
          </div>
          <div className='flex flex-col  w-56  md:w-60 '>
            <NumberTicker value={10000}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Hours Trained</p>
            <p className='text-[#475467]'>Comprehensive training for  thorough preparation.</p>
          </div>
          <div className='flex flex-col w-56  md:w-60 '>
            <NumberTicker value={2000}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Internships Offered</p>
            <p className='text-[#475467]'>Valuable internships for enhanced employability.</p>
          </div>
          

        </div>
        
    </div>
  )
}

export default CRTStatus