import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs, deleteJob } from '../../actions/jobActions';
import AdminJobCard from './AdminJobCard';
import PostJobForm from './PostJobForm';
import EditJobForm from './EditJobForm';
import { RootState, AppDispatch } from '@/actions/store';
import { Upload } from "lucide-react";
import { Job } from '@/types/types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setHasMore,deleteJobSuccess } from '../../reducers/jobReducers';

const AdminPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { jobs, loading, hasMore, fetchFailed } = useSelector((state: RootState) => state.job);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [isPostJobOpen, setIsPostJobOpen] = useState(false);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastJobElementRef = useRef<HTMLDivElement | null>(null);

  // Fetch jobs on mount and on page change only when needed
  const fetchJobsIfNeeded = useCallback(async () => {
    console.log(`Fetching jobs... Current Page: ${currentPage}, Loading: ${loading}, Has More: ${hasMore}`);
    if (!loading && hasMore) {
      const jobsInPage = await dispatch(fetchJobs(currentPage));
      // Check if `jobsInPage.payload` has fewer than 10 jobs
      if (jobsInPage && jobsInPage.payload && jobsInPage.payload.jobs.length < 10) {
        dispatch(setHasMore(false)); // Stop further fetches if fewer jobs on last page
      } else {
        // Only increment the page if new jobs were fetched successfully
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [dispatch, currentPage, loading, hasMore]);

  useEffect(() => {
    // Only fetch jobs on mount for the first page
    if (currentPage === 1) {
      dispatch(fetchJobs(1));
    }
  }, [dispatch, currentPage]);

  // After the first fetch completes, set currentPage to 2 for subsequent scroll loads
  useEffect(() => {
    if (jobs.length > 0 && currentPage === 1) {
      setCurrentPage(2); // Prepare for fetching page 2
    }
  }, [jobs,currentPage]);

  const handleDeleteJob = async (job: Job) => {
    try {
      await dispatch(deleteJob(job.id));
      dispatch(deleteJobSuccess(job.id));
      toast.success(`Job ${job.jobTitle} deleted successfully.`);
    } catch (error) {
      toast.error('Failed to delete job.');
      console.error('Failed to delete job:', error);
    }
  };

  const handleEditJob = (job: Job) => {
    setSelectedJob(job);
  };

  const handleJobEdited = (updatedJob: Job) => {
    setSelectedJob(null);
    dispatch({
      type: 'job/fetchJobsSuccess',
      payload: {
        jobs: jobs.map((job: Job) => job.id === updatedJob.id ? updatedJob : job),
        hasMore,
      }
    });
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMore && !loading) {
        fetchJobsIfNeeded(); // Fetch when the last job comes into view
      }
    },
    [fetchJobsIfNeeded, hasMore, loading]
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (lastJobElementRef.current) {
      observerRef.current.observe(lastJobElementRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [handleObserver]);

  return (
    <div className="relative my-16 mx-4 lg:mx-[20%]">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h1 className="text-2xl lg:text-3xl font-semibold my-4 sm:mb-0">
          Job Alerts & Opportunities
        </h1>
        <button
          onClick={() => setIsPostJobOpen(true)}
          className="flex items-center bg-purple-500 text-white my-4 p-2 rounded-lg hover:bg-purple-600"
        >
          <Upload className="w-4 h-4 mr-2" />
          Post Job
        </button>
      </div>
      <ToastContainer />
      <div className="space-y-6">
        {jobs.map((job: Job, index: number) => (
          <div
            key={job.id}
            ref={index === jobs.length - 1 ? lastJobElementRef : null} // Attach ref to last job element
          >
            <AdminJobCard
              job={job}
              onDelete={() => handleDeleteJob(job)}
              onEdit={() => handleEditJob(job)}
            />
          </div>
        ))}
        {loading && <p className="text-center">Loading more jobs...</p>}
      </div>
      {!hasMore && <p className="text-center">No more jobs available.</p>}
      {fetchFailed && (
        <div className="text-center mt-4 text-red-500">
          Failed to load jobs. Please check your network connection.<br />
          If the issue persists, feel free to contact us.
        </div>
      )}

      {isPostJobOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <PostJobForm closePopup={() => setIsPostJobOpen(false)} />
        </div>
      )}

      {selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <EditJobForm
            jobId={selectedJob.id}
            formData={selectedJob}
            closePopup={() => setSelectedJob(null)}
            onJobEdited={handleJobEdited}
          />
        </div>
      )}
    </div>
  );
};

export default AdminPage;
