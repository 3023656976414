import { ArrowLeft } from "lucide-react";
import React from "react";

const JobNotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center my-[12%] md:p-6">
      {/* Main content */}
      <h1 className="text-5xl md:text-6xl font-bold text-[#cacacb]">404</h1>
      <h2 className="text-2xl md:text-3xl font-semibold text-[#4b5563] mt-2 text-center">
        Job Not Found
      </h2>
      <p className="text-base md:text-lg text-gray-500 mt-4 max-w-lg text-center">
        Sorry, the job you’re looking for either doesn’t exist or may have been removed. Please check our job listings for the latest updates.
      </p>

      {/* Link to view all jobs */}
      <div className="flex justify-center items-center mt-6">
        <ArrowLeft className="text-[#6941C6] w-5 h-5 md:w-6 md:h-6" />
        <a href="/job-updates" className="ml-2 text-[#6941C6] text-lg md:text-xl font-bold hover:underline">
          View All Jobs
        </a>
      </div>
    </div>
  );
};

export default JobNotFound;
