import React from 'react'

const CRTBanner = () => {
  return (
    <div className='relative'>
        <div className='bg-[#53389E] w-screen  text-center h-fit py-3 '>
        <div className=' w-full mx-auto text-white py-12 md:py-24 px-7 md:px-14 lg:px-20'>
            <p>Info</p>
          <h1 className=' lg:text-5xl font-semibold md:text-3xl text-2xl md:leading-[5rem] my-3'>
            What is campus recruitment training (CRT)?
          </h1>
          <p className='pt-4 text-[#E9D7FE]  text-base md:text-xl px-0 md:px-6 lg:px-12'>
          Campus Recruitment Training (CRT) is a structured program designed to prepare students for employment by enhancing their skills in various areas. It typically includes training in aptitude, communication, resume writing, interview preparation, and job search strategies. CRT aims to bridge the gap between academic education and industry requirements, ensuring students are well-equipped to enter the job market successfully.
          </p>
        </div>
        </div>
        <img src='./crt-intro-banner.webp' alt="Banner" className='-mt-10  md:-mt-20 mx-auto md:w-[85%] md:h-[40rem] w-96 '/>
    </div>
  )
}

export default CRTBanner