import React from 'react'
import quiz from './quiz.png'
import Reading from './Reading.png'
import Skill from './Skill.png'
import { Logos } from '../../data/Logos'
import { Link } from 'react-router-dom'
const CRTAddons = () => {
  return (
    <div className='py-10 lg:px-20 md:px-14 px-7 bg-white'>
        <h1 className='text-headingBlack font-semibold text-2xl border-rose-50'>Additional Learning Enhancements</h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 my-5 gap-5 ' >
            <Link to='/quizzes'>
            <div className='shadow-sm hover:shadow-xl hover:scale-[1.01] transition-all ease-in-out duration-200 p-1 rounded-md'>
                <img src={quiz} alt="quiz banner" className='mb-3 rounded-md h-full '/>
                <span className='px-2 py-2 border rounded-full bg-[#f9f5ff] text-themepruple text-sm my-5 '> 
                    <span className='px-2 py-1 border rounded-full bg-white mr-1'>
                        Test your skills</span>
                    Unlimited
                </span>
                <div className='flex justify-between items-center mt-2'>
                    <h4 className='font-semibold text-headingBlack text-2xl  px-1'>Quiz</h4>
                    <Logos.ArrowUpRight/>
                </div>
                <p className='text-paraGray font-normal text-lg mt-1 px-1'>Test your knowledge with interactive quizzes designed to reinforce learning and challenge your skills !</p>
                <div className='flex flex-wrap gap-3 my-2 px-1'>
                    <span className='text-themepruple px-3 py-1 rounded-full border border-[#E9D7FE] bg-[#F9F5FF] '>HTML</span>
                    <span className='text-[#3538CD] px-3 py-1 rounded-full border border-[#C7D7FE] bg-[#EEF4FF] '>CSS</span>
                    <span className='text-[#067647] px-3 py-1 rounded-full border border-[#ABEFC6] bg-[#ECFDF3] '>Python</span>
                    <span className='text-[#C11574] px-3 py-1 rounded-full border border-[#FCCEEE] bg-[#FDF2FA] '>& many more..</span>
                </div>
            </div>
            </Link>
            <div className='flex flex-col gap-7'>
                <Link to='/reading-materials'>
                <div className='flex flex-col md:flex-row gap-4 rounded-md hover:shadow-xl hover:scale-[1.01] transition-all ease-in-out duration-200 items-center px-2'>
                    <img src={Reading} alt="Reading Materials" className='h-full p-1 w-72' />
                    <div>
                        <span className='px-2 py-2 border rounded-full bg-[#f9f5ff] text-themepruple text-sm my-5'> 
                            <span className='px-2 py-1 border rounded-full bg-white mr-1'>Learn...</span>
                            Beyond Limit
                        </span>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-headingBlack font-semibold text-lg my-2'>Reading Materials</h1>
                                <Logos.ArrowUpRight/>
                        </div>
                        <p className='text-[#475467] font-normal text-base'>
                        Our resources help you master essential coding concepts and advanced techniques, ensuring you're equipped with the best practices for real-world challenges.
                        </p>
                        <div className='flex gap-3 my-3'>
                            <span className='text-[#026AA2] px-3 py-1 rounded-full border border-[#B9E6FE] bg-[#FOF9FF] '>Java</span>
                            <span className='text-[#C11574] px-3 py-1 rounded-full border border-[#FCCEEE] bg-[#FDF2FA] '>Python</span>
                        </div>
                    </div>
                </div>
                </Link>
                <Link to='/quizzes'>
                <div className='flex flex-col md:flex-row gap-4 items-center hover:shadow-xl hover:scale-[1.01] transition-all ease-in-out duration-200 rounded-md px-2'>
                    <img src={Skill} alt="Skill " className='h-48 p-1 w-72'/>
                    <div>
                    <span className='px-2 py-2 border rounded-full bg-[#f9f5ff] text-themepruple text-sm my-5'> 
                            <span className='px-2 py-1 border rounded-full bg-white mr-1'>Test your skills</span>
                            Unlimited
                        </span>
                    <div className='flex justify-between items-center'>
                        <h1 className='text-headingBlack font-semibold text-lg my-2'>Building your API stack</h1>
                        <a href="/quizzes">
                            <Logos.ArrowUpRight/>
                        </a>
                    </div>
                    <p className='text-[#475467] font-normal text-base'>
                    Evaluate your progress through targeted skill assessments, ensuring you're on track with your personal growth and industry standards.
                    </p>
                    <div className='flex gap-3 my-3'>
                    <span className='text-[#067647] px-3 py-1 rounded-full border border-[#ABEFC6] bg-[#ECFDF3] '>Python</span>
                    <span className='text-[#C11574] px-3 py-1 rounded-full border border-[#FCCEEE] bg-[#FDF2FA] '>Java</span>
                    
                </div>
                    </div>
                </div>
                </Link>
                
            </div>

        </div>

    </div>
  )
}


export default CRTAddons