import { cn } from "../magicui/lib/utils";
import Marquee from "../magicui/marquee";
import React from 'react'

const reviews = [
  {
    name: "Mohan",
    username: "@Mohan",
    body: "I had a great internship. It's amazing. I love it.",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
  {
    name: "Sruthi",
    username: "@Sruthi",
    body: "Really great place to learn. I'm speechless. This is amazing.",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg",
  },
  {
    name: "Vivek",
    username: "@Vivek",
    body: "Really worth it ",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
  {
    name: "Ramesh",
    username: "@Ramesh",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
  {
    name: "Keerthi",
    username: "@Keerthi",
    body: "I'm at a loss for words.",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg",
  },
  {
    name: "Mahesh",
    username: "@Mahesh",
    body: "Great, it's really a good opportunity to learn something new",
    img: "https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg",
  },
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative w-64 h-32 cursor-pointer overflow-hidden rounded-xl border p-4  ",
        // light styles
        "border-gray-950/[.1] bg-white/[0.9]  hover:bg-white",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
      )}
    >
      <div className="flex flex-row items-center gap-2 ">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};



const MarqueeSection = () => {
  return (
    <div>
        <div className="relative flex h-[340px] w-full flex-col items-center justify-center overflow-hidden mx-auto ">
      <Marquee pauseOnHover className="[--duration:20s] ">
        {firstRow.map((review) => (
          
          <ReviewCard  key={review.username} {...review}  />
          
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover className="[--duration:20s] ">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-[#5133A0] dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-[#5133A0] dark:from-background"></div>
    </div>
        
        </div>
  )
}

export default MarqueeSection
