import { AnimatePresence, motion,useInView, Variants } from "framer-motion";
import { useRef } from "react";

import { cn } from "../magicui/lib/utils";

interface GradualSpacingProps {
  text: string;
  duration?: number;
  delayMultiple?: number;
  framerProps?: Variants;
  className?: string;
  inView?: boolean;
  inViewMargin?: string;
}

type MarginType = `${number}px` | `${number}px ${number}px` | undefined;

export default function GradualSpacing({
  text,
  duration = 0.5,
  delayMultiple = 0.04,
  framerProps = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  },
  className,
  inView = true,
  inViewMargin = "0px",
}: GradualSpacingProps) {
  const ref = useRef(null);
  const inviewMargin: MarginType = "0px"
  const inViewResult = useInView(ref, { once: true, margin: inviewMargin });
  const isInView = !inView || inViewResult;
  return (
    <div className="flex justify-center space-x-1">
      <AnimatePresence>
        {text.split("").map((char, i) => (
          <motion.h1
            ref={ref}
            key={i}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            exit="hidden"
            variants={framerProps}
            transition={{ duration, delay: i * delayMultiple }}
            className={cn("drop-shadow-sm ", className)}
          >
            {char === " " ? <span>&nbsp;</span> : char}
          </motion.h1>
        ))}
      </AnimatePresence>
    </div>
  );
}
