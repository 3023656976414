const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";

  export const postCertificate = async (payload: {
    id: string;
    userName: string;
    course: string;
    date: string;
    base64Image: string;
  }) => {
    const response = await fetch(`${baseUrl}certificate`, {
      headers: {
        "Content-Type": "application/json",
        "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
      },
      method: "POST",
      body: JSON.stringify(payload),
    });
  
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      console.log(response);
      return response.json();
    } else {
      const text = await response.text();
      throw new Error(`Unexpected response: ${text}`);
    }
  };
  
  // Add this line if you still encounter isolatedModules issue
  export {};
  