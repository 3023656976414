import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import FooterPage from "./Footer/Footerpage";
import Lenis from '@studio-freight/lenis'


const LayoutWithoutFooter = () => {
  
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2, // Adjust duration for smoothness
    });

    const raf = (time: number) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);

  }, []);

  return (
    <div>
      <Navbar />
      <Outlet />
      <FooterPage/>
    </div>
  );
};

export default LayoutWithoutFooter;
