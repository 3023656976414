import React from "react";
import CRTStatus from "./CRTStatus";
import CRTHeroSlide from "./CRTHeroSlide";
import {motion, useScroll, useTransform} from 'framer-motion'
import Ripple from "../magicui/ripple";
import CRTBanner from "./CRTBanner";

const CRTHero = () => {
  return (
    <div className="relative ">
      <Section1/>
      <Section2/>
    </div>
  );
};

export default CRTHero;




const Section1 = () => {
  const { scrollYProgress } = useScroll();

  // Moving heading out of the viewport as you scroll
  const heading = useTransform(scrollYProgress, [0, 0.15], [0, -3000]);
  const divMove = useTransform(scrollYProgress, [0, 0.07], ["0rem", "-47rem"]);
  const circle = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

  return (
    <div className="h-screen lg:h-[200vh]  text-[3.5vw] text-white pb-[10vh] sticky top-0 -z-10 overflow-hidden">
      
        {/* Mobile View */}
        <div className="lg:hidden mx-10 pt-20 overflow-hidden  h-full ">
          <h1 className="text-3xl font-semibold mb-3 text-headingBlack">Unlock Your Dream Career with Elite CRT Training</h1>
          <p className="text-xl text-paraGray ">Master the skills, strategies, and confidence needed to excel in campus placements. Gain an edge with expert-led training designed to prepare you for every stage of the recruitment process.</p>
          <img src="./crt-hero-banner.png" alt="" className="lg:hidden bg-white mx-auto" />
      </div>

      <div className='border border-transparent  w-full h-[40rem] mx-auto relative '>
      {/* Moving heading */}
      <motion.div style={{ y: heading }} className="absolute left-32 top-[20vh] hidden lg:block z-20 text-headingBlack w-[45%]">
        <h1 className="text-6xl font-semibold mb-6">Unlock Your Dream Career with Elite CRT Training</h1>
        <p className="text-xl text-paraGray ">Master the skills, strategies, and confidence needed to excel in campus placements. Gain an edge with expert-led training designed to prepare you for every stage of the recruitment process.</p>
      </motion.div>

  <div className=' h-96 w-96 mx-auto translate-x-80 relative mt-20 z-20'>

      <div className="fixed top-[39.4%] left-[7%]">
  <motion.div
    style={{ opacity: circle }}
    className=" backdrop-blur-md shadow-xl h-44 w-44 absolute rounded-full  z-30 hidden lg:flex justify-center items-center "
    >
      <h1 className="text-4xl md:text-5xl text-themepruple font-extrabold tracking-wider  flex justify-center items-center rounded-full border shadow-md px-3 py-10">
        CRT
      </h1>
    </motion.div>

<motion.div style={{ x: divMove }} className="bg-white h-[35rem] w-[60rem] absolute -top-[9rem] left-24  z-20 hidden lg:block ">

  <img src="./crt-hero-banner.png" alt="" className="bg-white mx-auto" />
</motion.div>
</div>
    </div>

      <Ripple
            className="absolute z-10 hidden -right-[30rem] lg:block "
            numCircles={3}
            mainCircleSize={200}
          />
        </div>

    </div>
  );
};



const Section2 = () => {
  return (
    <div className="h-fit lg:h-[200vh] ">
      <CRTHeroSlide/>
      <CRTStatus/>
      <CRTBanner/>
    </div>
  );
};
