import React, { useState } from "react";
import { CheckIcon, ChevronRightIcon } from "lucide-react";
import { subscribeEmail } from "../../services/mail.service";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState(false);
  const [error, setError] = useState("");

  // Helper function to validate email format
  const isValidEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle the subscription process
  const handleSubscribe = async () => {
    // If email is invalid, show error and do not proceed
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      // Attempt to subscribe the user by calling the database function
      const response = await subscribeEmail({ email });
      
      if (response && !response.error) {
        // If the email is successfully indexed, update the subscription status
        setSubscribeStatus(true);
        setError(""); // Clear any error message
      } else {
        // If subscription failed, show an error message
        setError("Subscription failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error during subscription:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="bg-[#3e1c96] text-white px-10 lg:px-24 py-8 flex md:justify-between md:items-center flex-col md:flex-row gap-4 md:gap-10 text-start w-full">
      <div>
        <h2 className="font-semibold text-[20px] py-1 text-[#F4F3FF]">
          Join Our Kamp
        </h2>
        <p className="font-light text-[#E9D7FE] py-1 mb-2 text-[16px]">
          We'll send you a weekly update with job opportunities, interview tips,
          and tricks. No spam, just valuable content!
        </p>
      </div>

      {/* Error message display */}
      {error && <p className="text-red-500 mt-2">{error}</p>}

      {/* Form for email input and subscribe button */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubscribe();
        }}
        className="flex gap-2 flex-col md:flex-row w-full md:w-auto"
      >
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="px-2 rounded-sm h-11 w-full md:w-64 py-1 text-black"
          required
        />

        {/* Button with dynamic content and styling */}
        <button
          type="submit"
          className={`h-11 w-full md:w-auto px-4 py-2 rounded-md flex items-center justify-center transition-colors ${
            subscribeStatus ? "bg-green-500" : "bg-[#7f70ed] hover:bg-[#6b5cd1]"
          } text-white`}
        >
          {subscribeStatus ? (
            <div className="flex items-center gap-2">
              <CheckIcon className="h-4 w-4" />
              <span>Subscribed</span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>Subscribe</span>
              <ChevronRightIcon className="h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
            </div>
          )}
        </button>
      </form>
    </div>
  );
};

export default NewsLetter;