import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the Job interface
import { Job } from '@/types/types';

// Define the state interface
interface JobState {
  jobs: Job[];
  job: Job | null; // To store the details of a single job
  loading: boolean;
  error: string | null;
  hasMore: boolean;
  page: number;
  fetchFailed: boolean; // Added flag to handle network failures
}

// Initial state
const initialState: JobState = {
  jobs: [],
  job: null, // Initialize as null
  loading: false,
  error: null,
  hasMore: true,
  page: 1,
  fetchFailed: false, // Initialize fetchFailed as false
};

// Create the slice
const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    fetchJobsRequest(state) {
      state.loading = true;
      state.error = null;
      state.fetchFailed = false; // Reset fetchFailed on a new request
    },
    fetchJobsSuccess(state, action: PayloadAction<{ jobs: Job[], hasMore: boolean }>) {
      state.jobs = [...state.jobs, ...action.payload.jobs];
      state.loading = false;
      state.hasMore = action.payload.hasMore;
      if (action.payload.hasMore) {
        state.page += 1; // Increment page only if there are more jobs to fetch
      }
    },
    fetchJobsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.fetchFailed = true; // Set fetchFailed to true on failure
    },
    fetchJobDetailsRequest(state) {
      state.loading = true;
      state.error = null;
      state.job = null; // Optionally reset the job details to null
    },
    fetchJobDetailsSuccess(state, action: PayloadAction<Job>) {
      state.job = action.payload;
      state.loading = false;
    },
    fetchJobDetailsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload;
    },
    deleteJobSuccess(state, action: PayloadAction<string>) {
      // Filter out the deleted job from the jobs array
      state.jobs = state.jobs.filter(job => job.id !== action.payload);
    },
  },
});


// Export actions
export const {
  fetchJobsRequest,
  fetchJobsSuccess,
  fetchJobsFailure,
  fetchJobDetailsRequest,
  fetchJobDetailsSuccess,
  fetchJobDetailsFailure,
  setHasMore,
  deleteJobSuccess,
} = jobSlice.actions;

// Export reducer
export const jobReducer = jobSlice.reducer;
export default jobReducer;
