import React, { useEffect } from "react";
// import TopFeatures from "./TopFeatures/TopFeatures";
// import TestYourSkills from "./TestYourSkills/TestYourSkills";
// import Learn from "./Learn/Learn";
import MainSection from "./MainSection";
import TestimonialsSlide from "./Testinomials/TestimonialsSlide";
import FAQ from "./FAQ";
import { FAQ_data } from "../data/FAQ";
import { useLocation } from "react-router-dom";
// import { BentocardSection } from "./Bentocard/BentocardSection";
// import { StickyScrollSection } from "./StickScroll/StickScrollSection";
import SocialProof from "./Socailproof/SocialProof";
import ApplyInternship from "./ApplyInternship";
import { BentoGridComponent } from "./Bentocard/BentoGridComponent";
import CustomStickyScroll from "./StickScroll/CustomStickyScroll";

const HomePage = () => {
  // console.log('data in homepage')
  // console.log(FAQ_data)
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
  <div>
      <MainSection />
      <div id="features">
        
      <BentoGridComponent/>
      </div>
     <CustomStickyScroll/>
      <SocialProof/>
        <TestimonialsSlide />
          <ApplyInternship/>
          <FAQ data={FAQ_data}/> 
    </div>
  );
};
          
          export default HomePage;

        
      
      
