export const projects = [
    {
      title: "Campus Recruitment Training (CRT)",
      description: "Boost your career with KampKode's Campus Recruitment Training (CRT) program. Gain expert guidance, practical skills, and hands-on experience for campus placements. Track your progress and achieve results that make a difference.",
      src: "./scrollStack/CRT.svg",
      link: "/crt",
      cta:"Join Now",
      color: "#7f70ed"
    },
    {
      title: "Internships",
      description: "Kickstart your career with KampKode's internships. Get practical experience, learn from industry professionals, and monitor your growth in real time. Our affordable internships equip you with the skills most sought after by employers, ensuring success.",
      src: "../scrollStack/intern.svg",
      cta:"Apply Now",
      link: "/internships",
      color: "#7251bc"
    },
    {
      title: "Real-World Projects",
      description: "Enhance your skills with KampKode's real-world project opportunities. Work on practical projects that prepare you for the challenges of the professional world. Learn from experts, monitor your progress, and build a portfolio that stands out in real world.",
      src: "./scrollStack/ss2.svg",
      cta:"Start Now",
      link:"/projects",
      color: "#5133A0"
    },
    
  ]